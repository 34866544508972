<template>
  <div class="discounts-goods">
    <div class="fiexd">
      <div class="search-box">
        <van-icon name="arrow-left" size="20" @click="navBack" />
        <div class="input-box">
          <van-icon name="search" size="17" />
          <input
            type="text"
            v-model="inputVal"
            placeholder="请输入关键词"
            class="input"
          />
        </div>
        <div class="search-button" @click="search">搜索</div>
      </div>
      <div class="discounts-money">
        <div>
          <div class="name">{{ nameCoupon }}</div>
          <div class="may-use">
            <span class="money" v-if="fullMoney > 0">{{
              `满￥${fullMoney}元减￥${money}元`
            }}</span>
            <span class="money" v-else>{{ `减￥${money}元` }}</span>
          </div>
        </div>
        <div class="look-btn" @click="lookDiscounts">查看优惠券</div>
      </div>
      <van-dropdown-menu>
        <van-dropdown-item
          title-class="font-weight"
          ref="menuList"
          :title="menuTitle"
        >
          <Class classType="2" @setClass="setClass"></Class>
        </van-dropdown-item>
        <van-dropdown-item
          :options="option2"
          v-model="sortVal"
          @close="dropdownClose"
          title-class="font-weight"
        />
      </van-dropdown-menu>
    </div>
    <div class="goods-list">
      <DiscountsGoods
        :money="money"
        :fullType="fullType"
        :fullMoney="fullMoney"
        ref="discount"
        :couponId="couponId"
      ></DiscountsGoods>
    </div>
  </div>
</template>

<script>
import Class from "../../home/consume-gold/components/class";
import DiscountsGoods from "../components/discounts-goods/discounts-goods";
import { getDiscountInfo } from "@/api/discounts.js";
export default {
  name: "discounts",
  components: {
    Class,
    DiscountsGoods,
  },
  data() {
    return {
      inputVal: "",
      menuTitle: "全部分类",
      couponId: this.$route.query.couponId || 0,
      grantId: this.$route.query.grantId || 0,
      detailId: this.$route.query.detailId || 0, // 优惠券记录ID
      money: 0,
      fullType: 0,
      fullMoney: 0,
      nameCoupon: "",
      option2: [
        { text: "综合排序", value: 1 },
        { text: "销量优先", value: 2 },
        { text: "评分优先", value: 3 },
        { text: "价格从低到高", value: 4 },
        { text: "价格从高到低", value: 5 },
      ],
      sortVal: 1,
    };
  },
  mounted() {
    this.getDiscountInfo();
  },
  methods: {
    setClass(classInfo) {
      this.$refs.menuList.toggle(false);
      this.classId = classInfo.id;
      this.menuTitle = classInfo.class_name;
      this.$refs.discount.initialValue({ classId: classInfo.id });
    },
    dropdownClose() {
      this.$refs.discount.initialValue({ orderBy: this.sortVal });
      this.showBack = false;
    },
    search() {
      this.$refs.discount.initialValue({ goodsName: this.inputVal });
    },
    lookDiscounts() {
      this.$router.push({ name: "my-discounts" });
    },
    async getDiscountInfo() {
      const res = await getDiscountInfo({
        coupon_id: this.couponId,
        grant_id: this.grantId,
        id: this.detailId,
      });
      if (res.code === 1) {
        this.money = res.data.money;
        this.fullType = res.data.full_type;
        this.fullMoney = res.data.full_money;
        this.nameCoupon = res.data.name;
        this.$refs.discount.initialValue({
          money: res.data.money,
          fullType: res.data.full_type,
          fullMoney: res.data.full_money,
        });
      }
    },
    navBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.fiexd {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
.search-box {
  display: flex;
  align-items: center;
  height: 57px;
  background-color: #fff;
  padding: 0 12px;
  .input-box {
    flex: 1;
    display: flex;
    align-items: center;
    height: 39px;
    border-radius: 39px;
    margin: 0 12px;
    box-sizing: border-box;
    border: 1px solid #fa0336;
    padding: 0 12px;
    .input {
      width: 100%;
      border: none;
      font-size: 18px;
    }
  }
  .search-button {
    width: 58px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    font-size: 18px;
    background: linear-gradient(260deg, #ed301d 0%, #fe5876 100%);
    border-radius: 35px;
  }
}
.discounts-money {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 81px;
  padding-left: 24px;
  padding-right: 13px;
  background-color: #fdeecd;
  .name {
    font-size: 18px;
    font-weight: 500;
  }
  .may-use {
    margin-top: 3px;
    .label {
      color: #6f6f6f;
      font-size: 16px;
    }
    .money {
      font-size: 16px;
      color: #ed301d;
    }
  }
  .look-btn {
    width: 106px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 28px;
    color: #e6241d;
    border: 1px solid #e6241d;
    font-size: 17px;
  }
}
.goods-list {
  margin-top: 200px;
  padding: 0 12px;
}
/deep/.font-weight {
  font-weight: 500;
  font-size: 18px;
}
/deep/.van-popup {
  background-color: #f9f9f9;
}
</style>
