<template>
  <van-list
    v-model="loading"
    :finished="finished"
    :immediate-check="false"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="goods">
      <div class="goods-list">
        <div class="grid" v-for="(item, index) in list" :key="index">
          <div class="goods-card" @click="goToDetail(item)">
            <div class="goods-img-box">
              <img
                v-show="false"
                src="@/assets/common/consumption-golabel.png"
                class="gold-tag"
                v-if="item.goods_type === 2"
              />
              <img
                src="@/assets/discounts/seckill@2x.png"
                class="seckill"
                v-else-if="item.goods_type === 1"
              />
              <img :src="item.cover_img" class="goods-img" />
            </div>
            <div class="goods-detail">
              <div class="goods-name">{{ item.good_name }}</div>
              <div class="welcome-extent">
                <img
                  src="@/assets/common/icon-good-opinion.png"
                  class="grade"
                />
                <div class="common">{{ item.score }}分</div>
                <div class="common">|</div>
                <div class="common">{{ item.total_sales }}人付款</div>
              </div>
              <div class="price">
                <Price :price="item.price"></Price>
                <div class="price-before">
                  <span>券后价</span>
                  <span>￥</span>
                  <span>{{
                    toInteger(Math.floor(item.laterPrice * 100) / 100)
                  }}</span>
                  <span>{{
                    twoFloating(Math.floor(item.laterPrice * 100) / 100)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-list>
</template>

<script>
import { getDiscountGoods } from "@/api/discounts.js";
import { toInteger, twoFloating, keepTwoDecimalFull } from "@/common/js/utils";
export default {
  props: {
    couponId: {
      default: 0,
    },
  },
  data() {
    return {
      list: [],
      tag_id: 1,
      getData: {
        page: 1,
      },
      loading: false,
      finished: false,
      money: 0,
      fullType: 0,
      fullMoney: 0,
    };
  },
  watch: {},
  mounted() {
    this.getData.coupon_id = this.couponId;
    // this.initialData();
  },
  methods: {
    onLoad() {
      this.updateData();
    },
    goToDetail(item) {
      let type = 1;
      let seckillId = 0;
      if (item.goods_type === 1) {
        type = 4;
        seckillId = item.seckill_id;
      } else {
        type = 1;
        seckillId = 0;
      }
      this.$router.push({
        name: "detail",
        query: {
          goodsId: item.id,
          type: type,
          seckillId: seckillId,
        },
      });
    },
    initialValue(data) {
      // 初始值
      if (data.couponId != undefined) {
        this.getData.coupon_id = data.couponId;
      }
      if (data.classId != undefined) {
        this.getData.class_id = data.classId;
      }
      if (data.orderBy != undefined) {
        this.getData.order_by = data.orderBy;
      }
      if (data.goodsName != undefined) {
        this.getData.goods_name = data.goodsName;
      }
      if (data.money) {
        this.money = data.money;
      }
      if (data.fullType) {
        this.fullType = data.fullType;
      }
      if (data.fullMoney) {
        this.fullMoney = data.fullMoney;
      }
      this.initialData();
    },
    initialData() {
      // 初始数据
      this.showLoadMore = false;
      this.list = [];
      this.getData.page = 1;
      this.getDiscountGoods("initial");
    },
    updateData() {
      ++this.getData.page;
      this.getDiscountGoods();
    },
    async getDiscountGoods(state) {
      const res = await getDiscountGoods(this.getData);
      if (res.code * 1 === 1) {
        if (res.data.list.length <= 0) {
          this.finished = true;
        } else {
          this.list = [...this.list, ...res.data.list];
          this.computePrice();
        }
      }
      this.loading = false;
    },
    computePrice() {
      // console.log("fullType-----------", this.fullType);
      // console.log("fullMoney-----------", this.fullMoney);
      // console.log("money-----------", this.money);
      this.list.map((item) => {
        if (this.fullType === 1 && this.fullMoney > item.price) {
          item.laterPrice =
            item.price - (item.price / this.fullMoney) * this.money;
          item.laterPrice = keepTwoDecimalFull(item.laterPrice, 2);
        } else {
          item.laterPrice = item.price - this.money;
          if (item.laterPrice <= 0) {
            item.laterPrice = 0;
          }
        }
        // console.log(item.laterPrice);
        return item;
      });
    },
    toInteger(num) {
      return toInteger(num);
    },
    twoFloating(num) {
      return twoFloating(num);
    },
  },
};
</script>

<style lang="less" scoped>
.goods-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .grid {
    position: relative;
    width: 189px;
    flex: 1;
    flex-shrink: 0;
    display: flex;
    margin-bottom: 10px;
    &:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.goods-card {
  position: relative;
  width: 189px;
  border-radius: 16px;
  background-color: #fff;
  .goods-img-box {
    position: relative;
    .gold-tag {
      position: absolute;
      top: 0;
      left: -2px;
      width: 54px;
      height: auto;
      z-index: 1;
    }
    .seckill {
      position: absolute;
      top: 0;
      left: 10px;
      width: 31px;
      height: auto;
      z-index: 1;
    }
    .stock {
      position: absolute;
      top: 0;
      left: 10px;
      width: 54px;
      height: auto;
      z-index: 1;
    }
  }
  .replace-money {
    position: absolute;
    top: 0;
    left: -6px;
    width: 100px;
    height: 44px;
  }
  .goods-img {
    width: 100%;
    height: 189px;
    border-radius: 16px 16px 0 0;
    background-color: rgb(250, 250, 250);
  }
}
.goods-detail {
  padding: 10px;
  .goods-name {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .welcome-extent {
    display: flex;
    align-items: center;
    margin: 5px 0;
    .grade {
      width: 13px;
      height: 13px;
      margin-right: 6px;
    }
    .common {
      color: #999999;
      font-size: 13px;
      &:nth-child(3) {
        margin: 0 10px;
      }
    }

    .jingdong-tag {
      display: table;
      padding: 1px 3px;
      color: #fff;
      background: linear-gradient(90deg, #f4180f 0%, #ff6731 100%);
      border-radius: 3px;
      font-size: 12px;
    }
    .tianmao-tag {
      display: table;
      padding: 1px 3px;
      border: 1px solid #985dff;
      color: #975bfe;
      border-radius: 3px;
      font-size: 12px;
    }
  }
}
.price {
  margin-top: 6px;
  display: flex;
  align-items: center;
  .price-now {
    margin-right: 6px;
    .price-common {
      font-size: 11px;
      color: #f2433a;
    }
    .integer {
      color: #f2433a;
      font-size: 18px;
    }
  }

  .price-before {
    font-size: 12px;
    color: #ed301d;
    margin-left: 4px;
  }
}
/deep/.uni-grid-item {
  width: 342px !important;
  margin-bottom: 18px;
  &:nth-child(2n + 1) {
    padding-right: 8px;
  }
  &:nth-child(2n) {
    padding-left: 8px;
  }
}
</style>
